import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import {graphql, Link} from "gatsby";

export const CardParagraph = ({node}) => {
    return (
        <div className="paragraph paragraph-card card relative flex flex-col h-full">
            {node.relationships.image && (
                <div className="card-image relative flex-initial">
                    <GatsbyImage
                        image={node.relationships.image.localFile.childImageSharp.gatsbyImageData}
                        alt={node.title} />
                </div>
            )}
            <div 
              className={`card-content relative py-6 px-2 border-b-20 border-secondary${node.relationships.image ? ` flex-1` : ` flex-initial`}`} 
              data-typesense-field="paragraph_card"
            >
                <div className="h-full flex flex-col">
                    <div className={`relative title h3 text-gray-500 ${node.text ? "mb-4 pb-4 before-underline before-bg-secondary" : ""}`}>
                      {node.title}
                    </div>

                    {node.text && (
                      <div
                        className={`content flex-1 text-sm${node.link ? ` mb-4` : ``}`}
                        dangerouslySetInnerHTML={{__html: node.text.value}}
                      />
                    )}

                    {node.link && (
                        <div className="link absolute bottom-0 right-6 -mb-2 transform translate-y-1/2">
                            <Link to={node.link.uri} className="flex items-center justify-center h-12 w-12 bg-primary border-2 border-secondary rounded-full text-white text-2xl">
                                <i className="fas fa-plus"><span className="hidden">En savoir plus</span></i>
                            </Link>
                        </div>
                    )}
                </div>

            </div>
        </div>
    );
}

export const fragment = graphql`fragment CardParagraph on paragraph__card {
  id
  image: field_image {
    alt
  }
  title: field_title
  text: field_text {
    value
  }
  link: field_link {
    title
    uri
  }
  relationships {
    image: field_image {
      localFile {
        childImageSharp {
          gatsbyImageData(
            width: 352
            height: 222
            quality: 100
            transformOptions: {cropFocus: CENTER}
            layout: CONSTRAINED
          )
        }
      }
    }
  }
}
`;
