import React from "react";
import {BannerParagraph} from "../components/Paragraphs/Banner";
import {CardParagraph} from "../components/Paragraphs/Card";
import {HighlightedParagraph} from "../components/Paragraphs/Highlighted";
import {ImageParagraph} from "../components/Paragraphs/Image";
import {ListParagraph} from "../components/Paragraphs/List";
import {TextParagraph} from "../components/Paragraphs/Text";
import {FilesMultiuploadParagraph} from "../components/Paragraphs/FilesMultiupload";
import {AccordionParagraph} from "../components/Paragraphs/Accordion"
import {CollapseParagraph} from "../components/Paragraphs/Collapse"
import {ImageGalleryParagraph} from "../components/Paragraphs/ImageGallery";
import {TextImageParagraph} from "../components/Paragraphs/TextImage";
import {ImageTextParagraph} from "../components/Paragraphs/ImageText";

const components = {
    paragraph__text: TextParagraph,
    paragraph__highlighted: HighlightedParagraph,
    paragraph__banner: BannerParagraph,
    paragraph__image: ImageParagraph,
    paragraph__card: CardParagraph,
    paragraph__list: ListParagraph,
    paragraph__files_multiupload: FilesMultiuploadParagraph,
    paragraph__accordion: AccordionParagraph,
    paragraph__collapse: CollapseParagraph,
    paragraph__image_gallery: ImageGalleryParagraph,
    paragraph__text_image: TextImageParagraph,
    paragraph__image_text: ImageTextParagraph,
};

export const getParagraph = (node) => {
    if (components.hasOwnProperty(node.type)) {
        const ParagraphComponent = components[node.type];
        return <ParagraphComponent key={node.id} node={node}/>;
    }

    return <p key={node.id}>Unknown type {node.__typename}</p>;
};
