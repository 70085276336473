import React from "react";
import {Link} from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

import Small16by10 from "../Images/Small16by10";
import { cssCleanClass } from "../../Utils/functions";

const ArticleListItem = ({content}) => (
    <div className="article-list-item card h-full flex flex-col">
        <div className="card-image relative flex-initial">
            <Link to={content.path.alias} className="block relative">
                {content.relationships.image ? (
                    <>
                        <GatsbyImage
                            image={content.relationships.image.localFile.childImageSharp.gatsbyImageData}
                            alt={content.title}
                            className="w-full"
                        />
                    </>
                ) : (
                    <Small16by10 alt={content.title}/>
                )}
                {(content.day && content.month) && (
                    <div className="absolute top-0 left-0">
                        <div className="inline-block py-2 px-4 bg-primary-light bg-opacity-80 text-white text-center">
                            <span className="text-4xl block leading-none">{content.day}</span>
                            <span className="font-semibold block lowercase leading-none">{content.month}{" "}{content.year}</span>
                        </div>
                    </div>
                )}
            </Link>
        </div>
        <div className="card-content flex-1 py-6 px-2 border-b-20 border-primary-light border-opacity-20">
            <span className={`tag theme theme-${cssCleanClass(content.relationships.tags.color)}`}>
                <Link to={`/actualites${content.relationships.tags.path.alias}`}>{content.relationships.tags.name}</Link>
            </span>
            <div className="title text-lg font-semibold font-important mb-2">
                <Link to={content.path.alias} className="text-gray-500">{content.title}</Link>
            </div>
        </div>
    </div>
)

ArticleListItem.propTypes = {
    content: PropTypes.object.isRequired,
}

export default ArticleListItem;
