import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import ArticleListItem from "../ListItems/ArticleListItem";

const OtherArticles = ({current}) => {
  const data = useStaticQuery(graphql`{
    otherArticles: allNodeArticle(
      filter: {status: {eq: true}}
      sort: {fields: [field_display_date, created], order: DESC}
      limit: 5
    ) {
      nodes {
        nid: drupal_internal__nid
        title
        day: field_display_date(formatString: "DD", locale: "fr")
        month: field_display_date(formatString: "MMM", locale: "fr")
        year: field_display_date(formatString: "YY", locale: "fr")
        body {
          summary
          value
        }
        path {
          alias
        }
        relationships {
          image: field_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 352
                  height: 222
                  quality: 100
                  transformOptions: {cropFocus: CENTER}
                  layout: CONSTRAINED
                )
              }
            }
          }
          tags: field_tags {
            name
            color: field_color
            path {
              alias
            }
          }
        }
      }
    }
  }`)

  let otherArticles = data.otherArticles.nodes.filter(({nid}) => {
    return nid !== current;
  })

  otherArticles = otherArticles.slice(0, 4);
  
  return otherArticles.length > 0 ? (
    <section className="section page-content-suggestion">
      <div className="container mx-auto px-4">
        <h2 className="title h2 text-primary">À lire aussi</h2>
        <div className="columns grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {otherArticles.map((node, index) => (
            <div key={index} className={`column article`}>
              <ArticleListItem content={node}/>
            </div>
          ))}
        </div>
      </div>
    </section>
  ) : (
    <></>
  )
}

export default OtherArticles