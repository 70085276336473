import React from "react";
import {graphql} from "gatsby";
import HtmlParser from "../HtmlParser";

export const TextParagraph = ({node}) => (
    <div className="paragraph paragraph-text">
        <div className="content" data-typesense-field="paragraph_text">
            <HtmlParser html={node.text.value}/>
        </div>
    </div>
)

export const fragment = graphql`
  fragment TextParagraph on paragraph__text {
    id
    text: field_text {
      value
    }
  }
`;
