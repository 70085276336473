import React from "react";
import {graphql} from "gatsby";
import HtmlParser from "../HtmlParser";

export const HighlightedParagraph = ({node}) => (
    <div className="paragraph paragraph-highlighted bg-gradient-to-r from-primary to-primary-light">
        <div className="relative p-8 text-white font-important font-semibold text-xl">
            <div className="content" data-typesense-field="paragraph_highlighted">
                <HtmlParser html={node.text.value}/>
            </div>
        </div>
    </div>
)

export const fragment = graphql`
  fragment HighlightedParagraph on paragraph__highlighted {
    id
    text: field_text {
      value
    }
  }
`;
