import React, {useContext} from "react";
import {graphql} from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import {ModalContext} from "../../contexts/ModalContext";
import {ModalImagesSlideshow} from "../Modal/ModalImagesSlideshow";

export const ImageGalleryParagraph = ({node}) => {
    const {setModalVisibility, setModalContent} = useContext(ModalContext)

    const handleImageClick = (index) => {
        setModalContent(
            <ModalImagesSlideshow active={index} images={node.relationships.imagesLarge} imagesData={node.images}/>
        )

        setModalVisibility(true)
    }

    return (
        <div className="paragraph paragraph-image-gallery">
            {node.title && (<h2 className={"title h2 text-primary"}>{node.title}</h2>)}
            <div className={`columns grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8`}>
                {node.relationships.images.map((image, index) => {
                    let imageData = node.images[index]

                    return (
                        <div key={index} className="column">
                            <figure className="image group relative cursor-pointer bg-primary" onClick={() => handleImageClick(index)}>
                                <GatsbyImage
                                    image={image.localFile.childImageSharp.gatsbyImageData}
                                    className="group-hover:opacity-50 transition-all duration-300"
                                    alt={imageData.alt}
                                    title={imageData.title} />
                                <figcaption className="image-caption absolute inset-x-0 top-1/2 p-2 text-center text-white transform -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-all duration-300">
                                    {imageData.title ? imageData.title : (
                                        <i className="fas fa-search-plus text-2xl"><span className="hidden">Agrandir</span></i>
                                    )}
                                </figcaption>
                            </figure>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}


export const fragment = graphql`fragment ImageGalleryParagraph on paragraph__image_gallery {
  id
  title: field_title
  images: field_images {
    alt
    title
  }
  relationships {
    imagesLarge: field_images {
      localFile {
        childImageSharp {
          gatsbyImageData(height: 625, quality: 100, layout: CONSTRAINED)
        }
      }
    }
    images: field_images {
      localFile {
        childImageSharp {
          gatsbyImageData(
            width: 352
            height: 352
            quality: 100
            transformOptions: {cropFocus: CENTER}
            layout: CONSTRAINED
          )
        }
      }
    }
  }
}
`;
