import React from "react";
import { graphql } from "gatsby";
import {getIconFromExtension} from "../../Utils/functions";

export const FilesMultiuploadParagraph = ({ node }) => {
    return (
        <div className={`paragraph paragraph-files-multiupload`}>
            {node.title && (<h2 className={"title h2 text-primary"}>{node.title}</h2>)}
            <ul className="files grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {node.relationships.files.map((file, index) => {
                    let fileData = node.files[index]
                    let fileName = fileData && fileData.description ? fileData.description : file.localFile.base

                    return (
                        <div key={index} className="file">
                            <div className="paragraph paragraph-file-multiupload h-full">
                                <a
                                    href={file.localFile.publicURL}
                                    title={`Télécharger "${file.localFile.base}" (${file.localFile.prettySize})`}
                                    className="relative flex items-baseline h-full px-4 py-6 border-l-8 border-secondary file bg-primary hover:bg-secondary text-white transiton-all duration-300"
                                    download
                                >
                                    <i className={`fas fa-${getIconFromExtension(file.localFile.extension)} text-2xl mr-2`}></i>
                                    <span className="icon-explain font-important font-semibold text-lg">{fileName}</span>
                                </a>
                            </div>
                        </div>
                    )
                })}
            </ul>
        </div>
    )
}

export const fragment = graphql`
    fragment FilesMultiuploadParagraph on paragraph__files_multiupload {
        id
        title: field_title
        files: field_files {
            description
        }
        relationships {
            files: field_files {
                localFile {
                    publicURL
                    prettySize
                    extension
                    base
                }
            }
        }
    }
`;