import React from "react";
import {graphql} from "gatsby";
import {getParagraph} from '../../helpers/paragraph-helpers';

export const ListParagraph = ({node}) => (
    <div className="paragraph paragraph-list">
        <div className={`columns grid grid-cols-1 md:grid-cols-2 lg:grid-cols-${node.itemsPerLine} gap-8`}>
            {node.relationships.listItems.map((listItem, index) => (
                <div key={index} className="column">
                    {getParagraph(listItem)}
                </div>
            ))}
        </div>
    </div>
);

export const fragment = graphql`
  fragment ListParagraph on paragraph__list {
    id
    itemsPerLine: field_items_per_line
    relationships {
      listItems: field_paragraphs {
        type: __typename
        ... on Node {
          ...TextParagraph
          ...HighlightedParagraph
          ...CardParagraph
          ...ImageParagraph
        }
      }
    }
  }
`;
