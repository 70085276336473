import React, {useEffect, useState} from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import HtmlParser from "../HtmlParser";

export const CollapseParagraph = ({ node, index, isOpenned, clickHandler, ...props }) => {
    const [collapsed, setCollapsed] = useState(!isOpenned);

    const toggle = clickHandler ? clickHandler : () => {
        setCollapsed(!collapsed)
    }

    useEffect(() => {
        setCollapsed(!isOpenned)
    }, [isOpenned])

    return (
        <div 
            className={`paragraph paragraph-collapse border-b-8 border-primary-light border-opacity-20${collapsed ? ` collapsed` : ` opened`}`}
            data-typesense-field="paragraph_collapse"
            {...props}
        >
            <div className="collapse-title flex items-center py-4 cursor-pointer" onClick={() => toggle(index)}>
                <div className="h3 flex-1 text-primary">
                    {node.title}
                </div>
                <div className="icon flex-initial">
                    <i className={`p-2 border rounded-full fas fa-${collapsed ? `plus border-primary-light bg-primary-light text-white` : `minus border-primary bg-primary text-white`}`}>
                        <span className="hidden">Déplier</span>
                    </i>
                </div>
            </div>
            <div className={`collapse-content px-2 py-4${collapsed ? ` hidden` : ` block`}`}>
                <div className="content text-gray-700">
                    <HtmlParser html={node.text.value}/>
                </div>
            </div>
        </div>
    )
}

CollapseParagraph.propTypes = {
    node: PropTypes.node.isRequired,
    index: PropTypes.number,
    isOpenned: PropTypes.bool,
    clickHandler: PropTypes.func
}

CollapseParagraph.defaultProps = {
    node: null,
    index: null,
    isOpenned: true,
    clickHandler: null
}

export const fragment = graphql`
  fragment CollapseParagraph on paragraph__collapse {
    id
    title: field_title
    text: field_text {
      value
    }
  }
`;
