import React from "react";
import {graphql} from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

export const ImageParagraph = ({node}) => {
  let {alt, width, height} = node.image
  let [maxWidth, maxHeight] = [576, 576]
  let calcHeight = height * maxWidth / width

  if (calcHeight > maxHeight) {
    maxWidth = maxHeight * maxWidth / calcHeight
  }

  return (
    <div className="paragraph paragraph-image">
      <figure className="image mx-auto" style={{maxWidth: `${maxWidth}px`}}>
        <GatsbyImage
          image={node.relationships.image.localFile.childImageSharp.gatsbyImageData}
          alt={alt} />
      </figure>
    </div>
  );
}

export const fragment = graphql`fragment ImageParagraph on paragraph__image {
  id
  image: field_image {
    alt
    width
    height
  }
  relationships {
    image: field_image {
      localFile {
        childImageSharp {
          gatsbyImageData(width: 576, quality: 100, layout: CONSTRAINED)
        }
      }
    }
  }
}
`;
