import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const Small16by9 = ({...props}) => {
  const data = useStaticQuery(graphql`{
  file(relativePath: {eq: "placeholder.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 500
        height: 315
        quality: 100
        transformOptions: {cropFocus: CENTER}
        layout: CONSTRAINED
      )
    }
  }
}
`)

  return <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} {...props} />;
}

export default Small16by9
