import React, {useContext, useEffect, useState} from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import {useSwipeable} from "react-swipeable";
import {ModalContext} from "../../contexts/ModalContext";

export const ModalImagesSlideshow = ({active, images, imagesData}) => {
    const first = 0;
    const last = images.length - 1;
    const [activeIndex, setActiveIndex] = useState(active)
    const {modalKeyDownEvent} = useContext(ModalContext)

    const next = () => {
        setActiveIndex(activeIndex === last ? first : activeIndex + 1)
    }

    const previous = () => {
        setActiveIndex(activeIndex === first ? last : activeIndex - 1)
    }

    const swipeHandler = useSwipeable({
        onSwipedLeft: eventData => next(),
        onSwipedRight: eventData => previous()
    })

    useEffect(() => {
        if (null !== modalKeyDownEvent && typeof modalKeyDownEvent !== 'undefined') {
            switch (modalKeyDownEvent.key) {
                case "ArrowLeft":
                    previous()
                    break;
                case "ArrowRight":
                    next()
                    break;
                default:
                    break;
            }
        }
    }, [modalKeyDownEvent])

    return (
        <div className="modal-images-slideshow relative w-full">
            <div className="images relative" {...swipeHandler}>
                {images.map((image, index) => {
                    let data = imagesData[index]

                    return (
                        <figure key={index} className={`image text-center ${index === activeIndex ? `block` : `hidden`}`}>
                            <GatsbyImage
                                image={image.localFile.childImageSharp.gatsbyImageData}
                                alt={data.alt}
                                title={data.title}
                                style={{maxHeight: "80vh"}} />
                            {data.title && (
                                <figcaption className="image-caption absolute top-full inset-x-0 text-center text-white">{data.title}</figcaption>
                            )}
                        </figure>
                    );
                })}
            </div>

            {last > first && (
                <nav>
                    <button
                        className="previous absolute bottom-0 m-1 top-1/2 right-auto left-0 flex items-center justify-center h-8 w-8 bg-primary border-2 border-primary rounded-full text-white transform -translate-y-1/2"
                        onClick={previous}
                    >
                        <i className="fas fa-chevron-left"><span className="hidden">Previous</span></i>
                    </button>

                    <button
                        className="next absolute bottom-0 m-1 top-1/2 left-auto right-0 flex items-center justify-center h-8 w-8 bg-primary border-2 border-primary rounded-full text-white transform -translate-y-1/2"
                        onClick={next}
                    >
                        <i className="fas fa-chevron-right"><span className="hidden">Next</span></i>
                    </button>
                </nav>
            )}
        </div>
    );
}