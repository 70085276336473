import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import {graphql, Link} from "gatsby";
import Layout from "../components/Layout";
import Metas from "../components/Metas";
import OtherArticles from "../components/Blocks/OtherArticles"
import HtmlParser from "../components/HtmlParser";
import {cssCleanClass} from "../Utils/functions";
import Breadcrumb from "../components/Breadcrumb";
import {getParagraph} from "../helpers/paragraph-helpers";

const Article = ({pageContext, data}) => {
    const paragraphs = data.node.relationships.paragraphs ? data.node.relationships.paragraphs.map(getParagraph) : null;

    let width = 0;
    let height = 0;
    let maxWidth = 470;
    let maxHeight = 470;

    if (data.node?.image) {
        width = data.node.image.width
        height = data.node.image.height
        let calcHeight = height * maxWidth / width

        if (calcHeight > maxHeight) {
            maxWidth = maxHeight * maxWidth / calcHeight
        }
    }

    return (
        <Layout contentType={data.node.internal.type}>
            <Metas title={data.node.title}/>
            <section className="section page-content">
                <div className="container mx-auto px-4 lg:max-w-screen-lg">
                    <Breadcrumb/>

                    <h1 className="title h1 text-primary mb-4" data-typesense-field="title">{data.node.title}</h1>

                    {data.node.date && (<div className="display-date inline-block px-2 mb-12 font-important font-semibold bg-primary text-white leading-loose rounded-lg">{data.node.date}</div>)}

                    {(data.node?.image && data.node.relationships.image) && (
                        <>
                            {(width/height) < 1 ? (
                                <div className="block figure relative mb-8 overflow-hidden bg-gray-100">
                                    <GatsbyImage
                                        image={data.node.relationships.imageBackground.localFile.childImageSharp.gatsbyImageData}
                                        alt={data.node.title}
                                        className="hidden md:block opacity-80 filter blur transform scale-105" />

                                    <figure className="static md:absolute z-10 inset-x-0 top-1/2 md:transform md:-translate-y-1/2 m-auto" style={{maxWidth: `${maxWidth}px`}}>
                                        <GatsbyImage
                                            image={data.node.relationships.image.localFile.childImageSharp.gatsbyImageData}
                                            alt={data.node.title} />
                                    </figure>

                                    <Link
                                        to={`/actualites${data.node.relationships.tags.path.alias}`}
                                        className={`tag-alternative theme theme-${cssCleanClass(data.node.relationships.tags.color)} absolute bottom-0 left-0`}
                                    >
                                        {data.node.relationships.tags.name}
                                    </Link>
                                </div>
                            ) : (
                                <div className="block figure relative mb-8">
                                    <GatsbyImage
                                        image={data.node.relationships.imageBackground.localFile.childImageSharp.gatsbyImageData}
                                        alt={data.node.title} />

                                    <Link
                                        to={`/actualites${data.node.relationships.tags.path.alias}`}
                                        className={`tag-alternative theme theme-${cssCleanClass(data.node.relationships.tags.color)} absolute bottom-0 left-0`}
                                    >
                                        {data.node.relationships.tags.name}
                                    </Link>
                                </div>
                            )}
                        </>
                    )}

                    {data.node.body && (
                        <div className="block content mb-8" data-typesense-field="body">
                            <HtmlParser html={data.node.body.value}/>
                        </div>
                    )}

                    {}
                    <div className="paragraphs-list">
                        {paragraphs}
                    </div>
                </div>
            </section>

            <OtherArticles current={pageContext.internalId}/>
        </Layout>
    );
}

export default Article

export const query = graphql`query ($internalId: Int!) {
  node: nodeArticle(status: {eq: true}, drupal_internal__nid: {eq: $internalId}) {
    title
    body {
      value
    }
    image: field_image {
      alt
      width
      height
    }
    changed
    date: field_display_date(formatString: "DD MMMM YYYY", locale: "fr")
    internal {
      type
    }
    relationships {
      image: field_image {
        localFile {
          childImageSharp {
            gatsbyImageData(height: 470, layout: CONSTRAINED)
          }
        }
      }
      imageBackground: field_image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 992
              height: 500
              transformOptions: {cropFocus: CENTER}
              layout: CONSTRAINED
            )
          }
        }
      }
      tags: field_tags {
        name
        color: field_color
        path {
          alias
        }
      }
      paragraphs: field_paragraphes {
        type: __typename
        ... on Node {
          ...TextParagraph
          ...BannerParagraph
          ...HighlightedParagraph
          ...ListParagraph
          ...FilesMultiuploadParagraph
          ...AccordionParagraph
          ...CollapseParagraph
          ...ImageGalleryParagraph
          ...ImageTextParagraph
          ...TextImageParagraph
          ...ImageParagraph
        }
      }
    }
  }
}
`
