import React, { useState } from "react";
import { graphql } from "gatsby";
import { CollapseParagraph } from "./Collapse";

export const AccordionParagraph = ({ node }) => {
    const [opened, setOpened] = useState(0);

    const toggle = (index) => {
        setOpened(index === opened ? null : index)
    }

    return (
        <div className="paragraph paragraph-accordion">
            {node.title && (
                <h2 className="title h2 text-primary" data-typesense-field="paragraph_accordion">{node.title}</h2>
            )}

            {node.relationships.collapses.map((collapse, index) => (
                <CollapseParagraph key={index} node={collapse} index={index} isOpenned={index === opened} clickHandler={toggle} />
            ))}
        </div>
    )
}

export const fragment = graphql`
    fragment AccordionParagraph on paragraph__accordion {
        id
        title: field_title
        relationships {
            collapses: field_paragraphs {
                type: __typename
                ... on Node {
                  ...CollapseParagraph
                }
            }
        }
    }
`;