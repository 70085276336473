import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import HtmlParser from "../HtmlParser";

export const ImageTextParagraph = ({ node }) => {
  let {alt, width, height} = node.image
  let [maxWidth, maxHeight] = [480, 380]
  let calcHeight = height * maxWidth / width

  if (calcHeight > maxHeight) {
      maxWidth = maxHeight * maxWidth / calcHeight
  }

  return (
    <div className="paragraph paragraph-image-text">
      {node.title && (<h2 className={"title h2 text-primary"}>{node.title}</h2>)}
      <div className={`columns grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8`}>
        <div className="column">
          <div className="w-full bg-gray-100">
            <figure className="image mx-auto" style={{maxWidth: `${maxWidth}px`}}>
              <GatsbyImage
                image={node.relationships.image.localFile.childImageSharp.gatsbyImageData}
                alt={alt} />
            </figure>
          </div>
        </div>
        <div className="column lg:col-span-2">
          <div className="content" data-typesense-field="paragraph_text">
            <HtmlParser html={node.text.value}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export const fragment = graphql`fragment ImageTextParagraph on paragraph__image_text {
  id
  title: field_title
  text: field_text {
    value
  }
  image: field_image {
    alt
    width
    height
  }
  relationships {
    image: field_image {
      localFile {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100, layout: CONSTRAINED)
        }
      }
    }
  }
}
`;
